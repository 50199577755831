@import url(https://fonts.googleapis.com/css?family=Rajdhani:300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
}

.webgl {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  outline: none;
}

.center{
  display: flex;
  align-items: center;
  justify-content: center;
}

#footer {
  height: 5.5vh;
  width: 100vw;
  position: fixed;
  bottom: 0;
}

#designerDivNameBox{
  height: 100%;
  min-width: 300px;
}

#designerName {
  color: whitesmoke;
  font-family: 'Rajdhani', sans-serif;
  position: absolute;
  z-index: 5;
  font-size: larger;
}

#header{
  height: 5.5vh;
  width: 100vw;
  position: fixed;
  top: 0;
}

#review-box{
  height: 100%;
  min-width: 100px;
  position: absolute;
  right: 0;
}

#review-span{
  color: whitesmoke;
  font-family: 'Rajdhani', sans-serif;
  position: absolute;
  z-index: 5;
  font-size: large;
}
* {
 outline: none;
 box-sizing: border-box;
}

html {
 box-sizing: border-box;
 -webkit-font-smoothing: antialiased;
 background-color: #2c847b;
}

main{
    background-color: #2c847b;
}

main{
    height: 100vh;
    min-width: 100vw;
}

.left-side{
    float: left;
    height: 100vh;
    width: 10vw;
    padding: 20px;
}

.lhs-div{
    height: 80%;
    width: 100%;
}

#lhs-side-text{
    color: #051d1a;
    font-size: larger;
    font-weight: 700;
    font-family: 'IBM Plex Mono', monospace;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.right-side{
    float: right;
    height: 100vh;
    width: 10vw;
    padding: 20px;
}

.rhs-div{
    height: 80%;
    width: 100%;
}

#rhs-side-text{
    color: #051d1a;
    font-size: larger;
    font-weight: 700;
    font-family: 'IBM Plex Mono', monospace;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
}

.middle{
    height: 100vh;
    min-width: 80vw;
}

.card{
    height: 80vh;
    width: 32%;
    border-radius: 2px;
    border: 3px solid black;
    background-color: #fffffe;
    flex-direction: column;
}

.review-form{
    height: 76%;
    width: 90%;
    border: 3px solid black;
    background-image: url(/static/media/review-background.7bc87e31.png);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

#review-name, input[type="text"]{
    height: 10%;
}

#review-body, textarea{
    height: 75%;
}

input[type="text"], textarea {
    background-color: rgba(255, 255, 255, 0.726);
    outline: none;
    border: none;
    color: #051d1a;
    font-weight: 500;
    font-family: 'IBM Plex Mono', monospace;
    font-size: medium;
    padding: 15px;
    border-radius: 4px;
    letter-spacing: 2px;
    width: 90%;
}

.review-form-article{
    height: 17%;
    width: 90%;
}

.name-article{
    height: 50%;
    width: 100%;
}

.review-form-footer{
    height: 50%;
    width: 100%;
}

.review-text{
    margin-right: 10px;
}

.name-article-text{
    font-size: larger;
    font-weight: bolder;
    font-family: 'IBM Plex Mono', monospace;
    letter-spacing: 5px;
}

.review-page-text{
    padding-left: 5px;
    background-color: #75c7bf;
}

.review-page-text:hover{
    background-color: #2c847b;
}

.review-form-footer-left{
    height: 100%;
    width: 75%;
}

.review-form-footer-right{
    height: 100%;
    width: 30%;
}

.review-form-date-text{
    font-family: 'IBM Plex Mono', monospace;
    font-size: small;
    font-weight: 600;
    letter-spacing: 2px;
}

.review-form-designer-text{
    font-family: 'IBM Plex Mono', monospace;
    font-size: small;
    font-weight: 700;
    margin-top: 3px;
}

.review-form-extra-text{
    font-family: 'IBM Plex Mono', monospace;
    font-size: 1rem;
    font-weight: 600;
    margin-top: 3px;
    letter-spacing: 2px;
    padding: 10px;
    background-color: #d0b17a;
    border-radius: 1px;
    color: aliceblue;
}

.review-error{
    width: 90%;
    margin: 0;
}

.review-form-error{
    color: rgb(225, 225, 225);
    font-size: small;
    font-weight: 500;
    font-family: 'IBM Plex Mono', monospace;
    background-color: #d63939;
    padding-left: 6px;
    padding-right: 6px;
    border-radius: 2px;
}

.review-form-extra-text:hover{
    cursor: pointer;
    background-color: #caa159;
}

@media screen and (max-width: 300px) and (min-width: 250px) {
    .card{
        height: 80vh;
        width: 95vw;
    }
    input[type="text"], textarea {
        font-weight: 400;
        font-size: small;
        padding: 13px;
        letter-spacing: 0px;
    }
    .review-form-date-text{
        font-size: smaller;
        font-weight: 400;
        letter-spacing: 0px;
    }
    .review-form-designer-text{
        font-size: smaller;
        font-weight: 500;
    }
    .review-form-extra-text{
        font-size: 0.7rem;
        font-weight: 500;
    }
    .name-article-text{
        font-size: medium;
        font-weight: 700;
        letter-spacing: 3px;
    }
}

@media screen and (max-width: 340px) and (min-width: 300px) {
    .card{
        height: 80vh;
        width: 95vw;
    }
    input[type="text"], textarea {
        font-weight: 400;
        font-size: small;
        padding: 13px;
        letter-spacing: 0px;
    }
    .review-form-date-text{
        font-size: smaller;
        font-weight: 400;
        letter-spacing: 0px;
    }
    .review-form-designer-text{
        font-size: smaller;
        font-weight: 500;
    }
    .review-form-extra-text{
        font-size: 0.7rem;
        font-weight: 500;
    }
    .name-article-text{
        font-size: medium;
        font-weight: 700;
        letter-spacing: 3px;
    }
}
@media screen and (max-width: 400px) and (min-width: 340px) {
    .card{
        height: 75vh;
        width: 90vw;
    }
}
@media screen and (max-width: 450px) and (min-width: 400px) {
    .card{
        height: 80vh;
        width: 80vw;
    }
}
@media screen and (max-width: 500px) and (min-width: 450px) {
    .card{
        height: 80vh;
        width: 75vw;
    }
}
@media screen and (max-width: 600px) and (min-width: 500px) {
    .card{
        height: 80vh;
        width: 68vw;
    }
}
@media screen and (max-width: 700px) and (min-width: 600px) {
    .card{
        height: 80vh;
        width: 58vw;
    }
}
@media screen and (max-width: 800px) and (min-width: 700px) {
    .card{
        height: 80vh;
        width: 60vw;
    }
}
@media screen and (max-width: 900px) and (min-width: 800px) {
    .card{
        height: 80vh;
        width: 45vw;
    }
}
@media screen and (max-width: 1000px) and (min-width: 900px) {
    .card{
        height: 80vh;
        width: 40vw;
    }
}
@media screen and (max-width: 1100px) and (min-width: 1000px) {
    .card{
        height: 80vh;
        width: 35vw;
    }
}
@media screen and (max-width: 1200px) and (min-width: 1100px) {
    .card{
        height: 80vh;
        width: 32vw;
    }
}
@media screen and (max-width: 1300px) and (min-width: 1200px) {
    .card{
        height: 80vh;
        width: 30vw;
    }
}
